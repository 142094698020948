.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #0E0E0E;
    height: 100vh;
    width: 100vw;
}

.app-logo {
    height: 80vmin;
    pointer-events: none;
}

.text-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin-left: -8%;
    margin-right: 10%;
    margin-top: -2%;
    max-width: 30rem;
}

h1 {
    font-family: 'Roboto', sans-serif;
    font-weight: bold;
    font-size: 4rem;
    line-height: 6rem;
    color: #FFFFFF;
    margin-bottom: 0;
}

h3 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: #C8C8C8;
    margin-top: 0;
}
